import {paths} from './constants';
import DashboardWhite from '../assets/images/DashboardWhite.png';
import AuditWhite from '../assets/images/AuditWhite.png';
import CareerWhite from '../assets/images/responseWhite.png';

interface propsMenuItem {
    name: string;
    to: string;
}

export interface propsMenuList {
    name: string;
    to: string;
    icon?: any;
    sub?: propsMenuItem[];
}

export const adminNavMenuList: propsMenuList[] = [
    {
        name: 'Join Me List',
        to: paths.admin.joinMe,
        icon: DashboardWhite
    },
    {
        name: 'Insight',
        to: paths.admin.insightList,
        icon: AuditWhite
    },
    {
        name: 'Career',
        to: paths.admin.career,
        icon: CareerWhite
    },

];
