import moment from 'moment';
import 'moment-timezone';

export const base64 = {
    encode(text: string) {
        return btoa(text);
    },
    decode(text: string) {
        return atob(text);
    }
}


export const numberFormat = (val: number = 0) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatDateTime = (date: any) => {
    let dateReturn = '-';
    try {
        if (date !== '' && date !== null) {
            const _date = moment.tz(date, moment.tz.guess()).toDate();
            dateReturn  = moment(_date).format('DD-MMM-YYYY hh:mm:ss A');
        }
    } catch (e) {
        dateReturn = '-';
    }

    return dateReturn;
}

export const formatDate = (date: any) => {
    let dateReturn = '-';
    try {
        if (date !== '' && date !== null) {
            const _date = moment.tz(date, moment.tz.guess()).toDate();
            dateReturn  = moment(_date).format('DD-MMM-YYYY');
        }
    } catch (e) {
        dateReturn = '-';
    }

    return dateReturn;
}

export const currencyFormat = (value: number) =>
    new Intl.NumberFormat('en-Us', {
        style: 'currency',
        currency: 'USD'
    }).format(value || 0);