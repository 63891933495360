import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { paths } from '../configs/constants';
import AuthLayout from '../components/layouts/authLayout';
import { AxiosProvider } from '../providers/AxiosProvider';
import AdminLayout from '../components/layouts/AdminLayout';


const Login = lazy(() => import('../pages/auth/commonLogin'));
const JoinMe = lazy(() => import('../pages/joinMe'));
const Insight = lazy(() => import('../pages/insight'));
const Career = lazy(() => import('../pages/career'));

function RouteApp() {
    return (<>
        <AxiosProvider />
        <Routes>
            <Route path={paths.admin.home} element={<AdminLayout />}>
                <Route path={paths.admin.joinMe} element={<Suspense fallback={""}><JoinMe /></Suspense>} />
                <Route path={paths.admin.insightList} element={<Suspense fallback={""}><Insight /></Suspense>} />
                <Route path={paths.admin.career} element={<Suspense fallback={""}><Career /></Suspense>} />
            </Route>
            <Route path={paths.home} element={<AuthLayout />}>
                <Route index element={<Suspense fallback={""}><Login /></Suspense>} />
            </Route>

        </Routes>
    </>);
}

export default RouteApp;